import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import { useToggle } from "use-reacts-hooks";
import { GET_GPTV_DATA, UPDATE_GPTV_DATA } from "api/APIs";
import { GPTV_UPDATED_AT_KEY } from "constants";

export const GPTVModal = ({ isBlackButton }) => {
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      paddingInline: 50,
      paddingBlock: 150,
    },
    gptvContent: {
      maxWidth: "85vw",
      maxHeight: "calc(100vh - 70px)",
    },
    wrapper: {
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      width: "fit-content",
      margin: "0 auto",
    },
    closeButton: {
      position: "absolute",
      zIndex: 2,
      width: "38px",
      height: "38px",
      border: isBlackButton
        ? "2px solid gray"
        : "1px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "50px",
      backgroundColor: isBlackButton
        ? "rgba(0, 0, 0, 0.7)"
        : "rgba(228, 216, 222, 0.2)",
      color: "white",
      right: 25,
      top: 25,
    },
  }));
  const classes = useStyles();
  const [gptvImage, setGptvImage] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toggleModal, setToggleModal] = useToggle(false);

  const updateGPTVStatus = () => {
    UPDATE_GPTV_DATA({ formId: gptvId })
      ?.then(() => {
        getGPTVStatus();
      })
      .catch((error) => {
        console.error("Error fetching GPTV data:", error);
      });
  };
  const handleClose = () => {
    if (
      !localStorage.getItem("suhail_user_id") &&
      gptvData.updatedAt &&
      gptvData.updatedAt !== localStorage.getItem(GPTV_UPDATED_AT_KEY)
    ) {
      localStorage.setItem(GPTV_UPDATED_AT_KEY, gptvData.updatedAt);
    }
    setToggleModal(false);
    updateGPTVStatus();
  };

  const [gptvData, setGptvData] = useState([]);
  const [gptvId, setGptvId] = useState(undefined);

  const fetchGPTVData = () => {
    fetch(`${process.env.REACT_APP_STRAPI_URL}/api/suhail-gptv?populate=*`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (!localStorage.getItem("suhail_user_id") && result.data?.updatedAt !== localStorage.getItem(GPTV_UPDATED_AT_KEY)){
          setToggleModal(true);
        }
        setGptvData(result.data);
      })
      .catch((error) =>
        console.error("There was a problem with your fetch operation:", error)
      );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (gptvData.image?.url) {
      setGptvImage(
        `${process.env.REACT_APP_STRAPI_URL}${
          windowWidth < 576 && gptvData.mobileImage?.url
            ? gptvData.mobileImage?.url
            : gptvData.image?.url
        }`
      );
    }
  }, [windowWidth, gptvData]);
  const getGPTVStatus = () => {
    GET_GPTV_DATA()
      ?.then((data) => {
        const fetchedId = String(data?.data?.data?.id);
        if (fetchedId) {
          setToggleModal(true);
          setGptvId(parseInt(fetchedId));
        }
      })
      .catch((error) => {
        console.error("Error fetching GPTV data:", error);
        setToggleModal(false);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("suhail_user_id")) {
      getGPTVStatus();
    } 
    fetchGPTVData();
  }, [localStorage.getItem("suhail_user_id")]);

  if (!gptvImage && !gptvData.html && !gptvData.video?.url) {
    return null;
  }

  return (
    <Modal open={toggleModal} className={classes.modal}>
      <div className={classes.wrapper}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          X
        </IconButton>
        {gptvImage ? (
          <img
            src={gptvImage}
            alt="GPTV img"
            className={classes.gptvContent}
            onClick={() => {
              if (gptvData.imageLink) {
                window.open(gptvData.imageLink);
              }
            }}
          />
        ) : gptvData.html ? (
          <div dangerouslySetInnerHTML={{ __html: gptvData.html }} />
        ) : (
          gptvData.video?.url && (
            <video
              controls
              className={classes.gptvContent}
              src={`${process.env.REACT_APP_STRAPI_URL}${gptvData.video?.url}`}
            >
              Your browser does not support the video tag.
            </video>
          )
        )}
      </div>
    </Modal>
  );
};